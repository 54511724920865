<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Special offer with a title, subtitle, and content',
      database: true,
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true },
        subtitle: { type: 'text', label: 'Sub-Title' },
        content: { type: 'wysiwyg', label: 'Content' },
        terms: { type: 'wysiwyg', label: 'Terms & Conditions' },
        percentOff: { type: 'text', label: 'Percent Off' },
        extraSavings: { type: 'text', label: 'Extra Savings' },
        endDate: { type: 'date', label: 'End Date' },
        image: { type: 'media', label: 'Image', breakpoints: {
          default: {
            width: 600,
            height: 400,
          },
          md: {
            width: 600,
            height: 800,
          },
        } },
        hideBookNow: { type: 'checkbox', label: 'Hide Book Now', default: false },
        imagesOnLeft: { type: 'checkbox', label: 'Images on Left?', instructions: '"Images on right by default"', default: false },
        bgsvg: { type: 'select', label: 'Content Background Graphic', options: { 'scribble-circle': 'Scribble Circle', 'spider-web': 'Spider Web', 'spiral-coral': 'Spiral Coral', 'spiral-coral-web': 'Spiral Coral Web' } },
        id: { type: 'text', label: 'ID' },
        htmlId: { type: 'text', label: 'Title' },
      },
      name: { label: 'Hyatt Special Offer', group: 'Hyatt' },
      templates: [{
        label: 'Special Offer',
        fields: {
          title: { value: 'Special Offer' },
          content: { value: '<p>Book now and save up to 55% off your stay at Hyatt Ziva Cancun. Plus, kids stay free!</p>' },
          image: { value: 'https://playa-cms-assets.s3.amazonaws.com/styled/jewel_palm_beach/23/jewel-palm-beach-aerial-resort-02.jpg/16e582077497fce8cc991d0081196430' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="DeviseHyattSpecialOffer">
    <div :id="fields?.id?.value" class="flex justify-center relative overflow-hidden">
      <div
        class="py-4 md:p-8 container text-center flex flex-col-reverse lg:flex-row items-center bg-zilara-cancun-grey-dark"
        :class="{ 'flex-row-reverse': fields?.imagesOnLeft?.value === true }"
      >
        <div :id="fields?.htmlId?.value" class="text-center lg:text-left lg:w-1/3 p-8 relative z-10">
          <div class="relative z-10">
            <h5 class="font-thin mb-4" v-html="fields?.title?.value" />
            <div class="font-light mb-1 text-4xl uppercase title" v-html="fields?.subtitle?.value" />
            <div class="wysiwyg leading-loose mb-4" v-html="fields?.content?.value" />
            <div class="lg:text-left">
              <h5 v-if="fields?.percentOff?.value">
                Save up to {{ fields?.percentOff?.value }}
              </h5>
              <span v-if="fields?.extraSavings?.value" v-html="fields?.extraSavings?.value" />
              <span v-if="fields?.endDate?.value"><br>Expires {{ fields?.endDate?.value }}</span>

              <div class="flex justify-center lg:justify-start">
                <PlayaBooking v-if="!fields?.hideBookNow?.value || fields?.hideBookNow?.value === '0'" wrapper-class="justify-center md:justify-start">
                  <button class="btn my-4">
                    Book Now
                  </button>
                </PlayaBooking>
              </div>

              <terms :terms="fields?.terms?.value">
                <button class="btn btn-text">
                  Terms & Conditions
                </button>
              </terms>
            </div>
          </div>
          <div
            v-if="$voix.breakpoint !== 'default' && fields?.bgsvg?.value"
            class="text-abs-white absolute z-0 inset-0 opacity-50"
          >
            <background-elements-scribble-circle
              v-if="fields?.bgsvg?.value === 'scribble-circle'" w="200%" h="200%"
              style="margin-top:-50%; margin-left:-50%"
            />
            <background-elements-spider-web
              v-if="fields?.bgsvg?.value === 'spider-web'" w="200%" h="200%"
              style="margin-top:-50%; margin-left:-50%"
            />
            <background-elements-spiral-coral
              v-if="fields?.bgsvg?.value === 'spiral-coral'" w="200%" h="200%"
              style="margin-top:-50%; margin-left:-50%"
            />
            <background-elements-spiral-coral-web
              v-if="fields?.bgsvg?.value === 'spiral-coral-web'" w="200%" h="200%"
              style="margin-top:-50%; margin-left:-50%"
            />
          </div>
        </div>

        <VoixMedia v-slot="slotProps" :field="fields?.image" background>
          <div
            class="w-full md:w-2/3 relative z-10 bg-cover h-64 lg:h-full bg-center inset-outline"
            :style="{ backgroundImage: `url(${slotProps.image})` }"
          />
        </VoixMedia>
        <background-elements-swoosh-white-right
          v-if="$voix.breakpoint !== 'default' && fields?.imagesOnLeft?.value === false"
          class="hidden absolute z-0  pointer-events-none" w="100%" h="100%"
        />
        <background-elements-swoosh-white-left
          v-if="$voix.breakpoint !== 'default' && fields?.imagesOnLeft?.value === true"
          class="hidden absolute z-0  pointer-events-none" w="100%" h="100%"
        />
      </div>
    </div>
  </div>
</template>
